import * as React from 'react';
import { graphql } from 'gatsby';
import { Suspense } from 'react';
import { navigate } from 'gatsby';

// services
import DataService from '../../../services/dataService';

import hexToRgba from 'hex-to-rgba'

// components
import Layout from '../../admin/Layout';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import  { 
    Input, 
    Button, 
    Paper, 
    Divider, 
    IconButton, 
    Grid,
    Tooltip, 
    Chip,
    Select,
    MenuItem,
    Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import { Editor } from '@tinymce/tinymce-react';
import { SettingCategory, Wazap } from '../../../helpers/admin/SectionEditHelper';

import { findAll } from 'unist-utils-core';

import { htmlToStructuredText, visitChildren } from 'datocms-html-to-structured-text';
import { render } from 'datocms-structured-text-to-html-string';

import { CloudinaryContext, Image } from 'cloudinary-react';

// helpers
// import InputHelpers from '../../../helpers/inputHelpers';
// import { fontFamily } from '@mui/system';
// import { colorSpaceFromICC } from '@cloudinary/url-gen/actions/delivery';
import { ps } from '@cloudinary/url-gen/qualifiers/format';
import { siteMetadata } from '../../../../gatsby-config';

import { useDispatch, useSelector } from 'react-redux';
import { setUpdatingData } from '../../../redux/admin/adminSlice';

const EditPost = ({ data, location, pageContext }) => {

    const updatingData = useSelector( state => state.admin.updatingData );
    const dispatch = useDispatch();

    const [post, setPost] = React.useState(null);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState();
    const [content, setContent] = React.useState('');
    const [imgurl, setimgurl] = React.useState(null);
    const [imageId, setImageId] = React.useState();
 
    const [tags, setTags] = React.useState([]);
    const [selectedTag, setSelectedTag] = React.useState("None");

    const [isDraft, setIsDraft] = React.useState(false);

    const [postTitleFont, setPostTitleFont] = React.useState('');
    const [postTitleFontSize, setPostTitleFontSize] = React.useState(0);
    const [postContentFontFamily, setPostContentFontFamily] = React.useState('');
    const [fonts, setFonts] = React.useState([
        'serif',
        'sans-serif',
        'monospace',
        'cursive',
        'fantasy'
    ]);
    const [showMoreOptions, setShowMoreOptions] = React.useState(false);
    const [menusDismissed, dismissMenus] = React.useState(false);
    const [showImageOverlay, setShowImageOverlay] = React.useState(false);
    const [showSelectImagesOverlay, setShowSelectImagesOverlay] = React.useState(false);

    const [yesOverlay, setYesOverlay] = React.useState(false);

    const mainRef = React.useRef();
    const editorRef = React.useRef(null);

    const moreOptionsRefState = React.useRef();
    
    const setMoreOptionsRefState = showBool => {
        if (!showBool) {
            moreOptionsRefState.current = showBool;
            setShowMoreOptions(showBool);
        }
    }


    React.useEffect(() => {

        getPost();
    }, []);

    const getPost = async () => {

        // dispatch(setUpdatingData({ updatingData: true }));


        let pId = '';
        console.log(location.state)
        if (location.state) {
            localStorage.setItem('editPagePostId', location.state.postId);
            pId = location.state.postId; 
        } else {
            pId = localStorage.getItem('editPagePostId');
        }

        DataService.getRecord( pId, true ).then( post => {

            // set initial value for post title and content
            setPost(post)
            setTitle(post.title);
            setContent(post.htmlcontent);
            setDescription(post.description);
            setPostTitleFont(post.titleFontFamily);
            setPostTitleFontSize(post.titleFontSize);
            setPostContentFontFamily(post.paragraphFontFamily);
            setIsDraft(post.draft)

            if (post.image !== null) {
                DataService.getRecord(post.image).then( image => {
                    setimgurl(image.name);
                    setImageId(image.id);
                })
            }
            if (post?.tags !== null) {
                getTags(post?.tags);
            }
        });

        // dispatch(setUpdatingData({ updatingData: false }));
    }

    const getTags = (tags) => {
        
        dispatch(setUpdatingData({ updatingData: true }));

        DataService.getRecordsByIds(tags)
            .then( allTags => {
                setTags(allTags);
                dispatch(setUpdatingData({ updatingData: false }));
            }
        );
    }

    const convertEditorContentToStructuredText = async () => {

        const html = editorRef.current?.getContent();
        console.log(html)
        const structuredText = await htmlToStructuredText(html);
        // console.log(structuredText);
        setContent(structuredText);

    }

    const saveAndSetHtmlContent = () => {
        const html = editorRef.current?.getContent();
        setContent(html)
    }

    const updatePost = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        const combinedPostData = {
            title,
            description,
            slug: title.split(' ').join('-'),
            htmlcontent: content,
            image: imageId,
            draft: isDraft
        };

        // update content
        DataService.updateRecord(post.id, combinedPostData)
            .then( _ => {
                dispatch(setUpdatingData({ updatingData: false })) 
            })
            .catch( err => console.error(err.message))
    }

    const addTagToPost = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        if (selectedTag !== "None") {
            DataService.updateRecord(post.id, { tags: [...post.tags, selectedTag.split('-')[1]] })
                .then( post => {
                    getTags(post.tags);
                    dispatch(setUpdatingData({ updatingData: false }));
                }
            );
        }
    }

    const deleteTag = (tagId) => {
        
        const confirm = window.confirm("Are you sure you want to remove this tag?")

        dispatch(setUpdatingData({ updatingData: true }));

        if (confirm) {
            let newTags = tags.map( tag => tag.id );
            newTags.splice(newTags.indexOf(tagId), 1);

            DataService.updateRecord(post.id, { tags: newTags }).then( post => getTags(post.tags) )
        }

        dispatch(setUpdatingData({ updatingData: true }));
    }

    const deletePost = () => {

        const confirm = window.confirm("Are you sure you want to delete this post?");

        dispatch(setUpdatingData({ updatingData: true }));

        if (confirm) {
            DataService.deleteRecord(post.id)
                .then( _ => navigate('/admin/posts'));
        }

        dispatch(setUpdatingData({ updatingData: true }));
    }

    const handleShowSelectImagesOverlay = (value) => {
        setShowSelectImagesOverlay(false)
    }

    const handleSetYesOverlay = (value) => {
        setYesOverlay(value);
        // console.log(yesOverlay)
    }

    const reloadPostData = () => getPost();

    const updateImage = (newImageId) => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.getRecord(newImageId)
            .then( image => {
                getPost();
            })

        dispatch(setUpdatingData({ updatingData: true }));
    }

    const dismissMoreOptions = () => dismissMenus(!menusDismissed);

    return (
        <Layout
            ref={mainRef}
            showMoreOptions={showMoreOptions}
            setShowMoreOptions={setMoreOptionsRefState}
        >

            <div className="flex flex-row-reverse">

            <div className="flex flex-col">

            {post == null ? (
                <SuspenseSkeleton />
            ) : (
                    <Paper elevation={1} style={{ position: 'relative', display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                        <div>
                            <div md={6} display="flex" flexDirection={"column"} justifyContent="center">
                                <h2 className='p-4 pt-6 text-base font-semibold text-center' style={{ fontFamily: 'monospace', margin: 'auto', marginBottom: 0, marginTop: 0 }}>Title</h2>
                                <Divider />
                                <TextField 
                                    placeholder="Title"
                                    defaultValue={post.title}
                                    variant="standard"
                                    style={{
                                        justifyContent: 'center',
                                        margin: 20,
                                        height: 50,
                                        margin: 'auto',
                                        alignSelf: 'center',
                                        marginTop: 0,
                                        marginBottom: 0,
                                        margin: 'auto',
                                        width: '100%'
                                    }}
                                    sx={{
                                        '& .MuiInput-root': {
                                            width: '80%',
                                            margin: 'auto',
                                        }
                                    }}
                                    onChange={(e) => setTitle(e.target.value) }
                                />
                            </div>
                            <div
                                item 
                                md={6}
                            >
                                <div 
                                    style={{
                                        width: '100%',
                                        height: 200,
                                        backgroundColor: hexToRgba('#45425a', 0.5),
                                        position: 'relative',
                                    }}
                                    
                                    onMouseOver={() => {
                                        console.log('hi');
                                        setShowImageOverlay(true) 
                                    }}
                                    onMouseLeave={() => {
                                        console.log('out')
                                        setShowImageOverlay(false)
                                    }}
                                    onClick={() => setYesOverlay(true) }
                                >   
                                    <CloudinaryContext cloudName="dbs3cwu82"
                                        style={{
                                            display: 'flex',
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <Image 
                                            style={{
                                                margin: 'auto',
                                                backgroundColor: 'red',
                                                height: '100%'
                                            }}
                                            publicId={imgurl}
                                        />
                                        {
                                            showImageOverlay ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundColor: hexToRgba('#45425a', 0.7)
                                                    }}
                                                >
                                                    <div 
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                            color: 'whitesmoke'
                                                        }}
                                                    >
                                                        <AddIcon 
                                                            style={{ 
                                                                alignSelf: 'center'
                                                            }} 
                                                        />
                                                        <p style={{ 
                                                            fontSize: 20, 
                                                            fontfamily: 'monospace' 
                                                        }}>Change Image</p>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundColor: hexToRgba('#45425a', post?.image == null ? 0.5 : 0.1)
                                                    }}
                                                >
                                                    {post?.image == null && (
                                                        <div 
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                height: '100%',
                                                                color: 'whitesmoke'
                                                            }}
                                                        >
                                                            <p style={{ 
                                                                fontSize: 20, 
                                                                fontfamily: 'monospace' 
                                                            }}>Image</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    </CloudinaryContext>
                                </div>
                            </div>
                        </div>
                        
                        {
                            yesOverlay == true && (
                                <Wazap 
                                    handleSetYesOverlay={handleSetYesOverlay} 
                                    siteId={data.datoCmsWebsite.id}
                                    postId={location.state.postId}
                                    reloadPostData={reloadPostData}
                                    updateImage={updateImage}
                                />
                            )
                        }
                    </Paper>
                
                )}

                    <Paper className="w-full p-2 mb-5">
                        <div className="flex flex-col justify-center">
                            <p className="text-base font-semibold p-5 pb-4 text-center" style={{ fontFamily: 'monospace', }}>Description</p>
                            <Divider style={{ marginBottom: '1rem' }} />
                            {/* {isDraft ? isDraft.toString() : ''} */}
                            <TextField 
                                placeholder="Description"
                                varient="standard"
                                multiline
                                rows={4}
                                onChange={(e) => setDescription(e.target.value) }
                            />
                        </div>
                    </Paper>

                    <Paper className="w-full p-2 mb-5">
                        <div className="flex justify-center">
                            <p className="text-base font-semibold p-5 pb-4 text-center" style={{ fontFamily: 'monospace', }}>Draft Mode</p>
                            <Divider orientation="vertical" className='h-auto' />
                            {/* {isDraft ? isDraft.toString() : ''} */}
                            <Switch 
                                // defaultChecked={post?.draft}
                                checked={isDraft}
                                className="m-auto"
                                onChange={(e) => {
                                    // console.log(e.target.checked)
                                    setIsDraft(e.target.checked); 
                                }}
                            />
                        </div>
                    </Paper>

                    <Paper className="w-full p-4">
                        <div className='flex flex-col'>
                            <p className="text-base font-semibold p-5 pb-4 text-center" style={{ fontFamily: 'monospace' }}>Tags</p>
                            <div className="mb-2" style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}>
                                {tags.map( tag => (
                                    <Chip 
                                        icon={<LocalOfferOutlinedIcon color="white" style={{ color: 'white' }} />} 
                                        deleteIcon={<DeleteForeverOutlined style={{ color: 'white' }} />}
                                        onDelete={() => deleteTag(tag.id) }
                                        label={tag.title} 
                                        style={{ 
                                            margin: '0.5rem',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            padding: '0.2rem',
                                            flex: 1,
                                            justifyContent: 'space-between'
                                        }} 
                                    />
                                ))}
                            </div>
                            <Divider />
                            <div className='p-4 flex flex-col flex-1 m-0 mb-2'>
                                <Select
                                    value={selectedTag}
                                    defaultValue={"None"}
                                    onChange={(e) => setSelectedTag(e.target.value)}
                                    className="m-0 mb-2"
                                >
                                    <MenuItem value={"None"}>
                                        Choose a Tag to add
                                    </MenuItem>
                                    {
                                        data?.allDatoCmsTag.nodes.filter( tag => !post?.tags.includes(tag.id.split('-')[1]) ).map( tag => (
                                            <MenuItem value={tag.id}>
                                                {tag.title}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                <Button
                                    disabled={selectedTag == "None"} 
                                    variant="outlined"
                                    onClick={() => addTagToPost() }
                                    style={{
                                        color: 'black',
                                        borderColor: 'black',
                                        opacity: selectedTag == "None" ? 0.2 : 1
                                    }}
                                >
                                    Add Tag
                                </Button>
                            </div>
                        </div>
                    </Paper>

                </div>

                <div className='flex-1 m-5 mt-0'>
                    <Editor 
                        apiKey={siteMetadata.TinyMCEApiKey}
                        onInit={(evt, editor) => {
                            editorRef.current = editor;
                        }}
                        init={{
                            height: 700,
                            content_style: "body { width: '100%' }"
                        }}
                        value={content}
                        onEditorChange={saveAndSetHtmlContent}
                        style={{
                            padding: 10,
                            width: '90%',
                            marginTop: 20,
                            height: '80%',
                            flex: 1
                        }}
                    />
                </div>

                <Tooltip 
                    title={"Update Section"} 
                    placement='top'
                    className="hi"
                    style={{
                        position: 'fixed',
                        bottom: 40,
                        left: '22rem',
                        backgroundColor: 'lightblue',
                        color: 'grey',
                        width: 50,
                        height: 50,
                        WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                        boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.80)' 
                    }}
                >
                    <IconButton 
                        onClick={() => updatePost() }
                    >
                        <UpgradeIcon style={{ textAlign: 'right', fontSize: 30, color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip 
                    title={"Delete Page"} 
                    placement='top'
                    className="hi"
                    style={{
                        position: 'fixed',
                        bottom: 40,
                        left: '18rem',
                        backgroundColor: 'darkred',
                        width: 50,
                        height: 50,
                        WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                        boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.80)' 
                    }}
                >
                    <IconButton 
                        onClick={() => deletePost() }
                    >
                        <DeleteForeverOutlined style={{ textAlign: 'right', fontSize: 30, color: 'whitesmoke' }} />
                    </IconButton>
                </Tooltip>
            </div>
        </Layout>
    )
}


const SuspenseSkeleton = () => {

    return (
        <Stack spacing={1}>
            <Skeleton varient="rectangular" width={50} />
            <Skeleton varient="text" />
            <Skeleton varient="text" />
            <Skeleton varient="text" />
        </Stack>
    )
}


export const data = graphql`
    query allSiteTagsQuery($sitename: String) {
        allDatoCmsTag(filter: {site: {name: {eq: $sitename}}}) {
            nodes {
                id
                title
            }
        }

        datoCmsWebsite(name: {eq: $sitename}) {
            id
        }
    }
`


export default EditPost;